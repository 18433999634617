import React, { useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Join from "../join/Join";
import { GlobalContext } from "../context/GlobalContext";
import ProfileCard from "./ProfileCard";
import useGoBack from "../utils/useGoBack";

function Profile() {
  const { globalState } = useContext(GlobalContext);
  const [showJoin, setShowJoin] = useState(false);
  const navigate = useNavigate();
  const onLogin = () => {
    const returnPath = localStorage.getItem("returnPath");
    if (returnPath) {
      localStorage.removeItem("returnPath");
      navigate(returnPath);
    } else {
      navigate("/");
    }
  };
  const goBack = useGoBack();
  return (
    <Routes>
      {!globalState.user ? (
        <Route
          path="*"
          element={<Join onLogin={onLogin} onCancel={goBack} />}
        />
      ) : (
        <Route path="/" element={<ProfileCard />} />
      )}
    </Routes>
  );
}

export default Profile;
