// src/pages/DatesDetails.js

import React, { useEffect, useState, useContext } from "react";
import Details from "../components/Details/Details";
import "./DatesDetails.scss";
import { useLocation, Link, useParams } from "react-router-dom";
import {
  CheckIcon,
  ArrowRightStartOnRectangleIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import useHideNav from "../utils/useHideNav";
import { GlobalContext } from "../context/GlobalContext";
import useEventStreamingWithGroupChatCount from "./hooks/useEventStreamingWithGroupChatCount";
import EventsAPI from "../api/EventsAPI";
import SplashScreen from "../components/SplashScreen";
import Utility from "../utils/utility";
import useAuthRedirect from "../utils/useAuthRedirect";
const DatesDetails = ({ children }) => {
  const location = useLocation();
  const { event } = location.state || {};
  const { globalState } = useContext(GlobalContext);
  const [genderType, setGenderType] = useState("");
  const { eventID } = useParams();

  useHideNav();

  useEffect(() => {
    const gType =
      Utility.getGenderTypeFromGender(globalState.user?.gender, false) + "s";
    setGenderType(gType);
  }, [globalState.user]);

  const { eventStreaming } = useEventStreamingWithGroupChatCount(
    event,
    eventID,
    genderType
  );

  if (!eventStreaming) {
    return <SplashScreen />;
  }

  const hasPassedByAtLeast = (targetDate, hours) => {
    if (typeof hours !== "number") {
      throw new Error("Invalid hours value");
    }

    let targetDateTime;

    if (targetDate instanceof Date) {
      targetDateTime = targetDate;
    } else if (
      typeof targetDate === "object" &&
      targetDate !== null &&
      "seconds" in targetDate &&
      "nanoseconds" in targetDate
    ) {
      targetDateTime = new Date(
        targetDate.seconds * 1000 + targetDate.nanoseconds / 1000000
      );
    } else {
      throw new Error("Invalid date object");
    }

    const currentDate = new Date();
    const diffInMilliseconds = currentDate - targetDateTime;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    return diffInHours >= hours;
  };

  return (
    <>
      <Details event={eventStreaming} backPath="/dates">
        <DetailsBtn
          event={eventStreaming}
          to={`/dates/group-chat/${eventID}/${genderType}`}
          icon={<ChatBubbleLeftRightIcon />}
          label={`${genderType} Chat`}
          cls="groupChatBtn"
          notificationField={`${genderType}ChatCount`}
        />
        {hasPassedByAtLeast(eventStreaming.date, -72) && !eventStreaming.isCompleted && (
          <DetailsBtn
            event={eventStreaming}
            to={`/dates/check-in/${eventID}`}
            icon={<CheckIcon />}
            label="Check-in"
            showAnimation={eventStreaming?.activeSession?.at != undefined}
          />
        )}
        {eventStreaming.isCompleted && (
          <DetailsBtn
            event={eventStreaming}
            to={"/dates/date-match"}
            icon={<SparklesIcon />}
            label="Match"
          />
        )}
        {!hasPassedByAtLeast(eventStreaming.date, -48) && (
          <DetailsBtn
            event={eventStreaming}
            to={"/dates"}
            icon={<ArrowRightStartOnRectangleIcon />}
            label="Leave Event"
            cls="leaveBtn"
            onClick={() =>
              EventsAPI.removeUserFromEvent(
                event.id,
                globalState.user.userID,
                globalState.user.gender
              )
            }
          />
        )}
      </Details>
    </>
  );
};

export default DatesDetails;

function DetailsBtn({
  event,
  to,
  icon,
  label,
  cls = "dateBtn",
  onClick = null,
  notificationField,
  showAnimation
}) {
  const isNumber = (value) => {
    return typeof value === "number" && !isNaN(value) && Number.isFinite(value);
  };
  const notificationFieldData = event && event[notificationField];
  return (
    <Link to={to} state={{ event }} className={cls} onClick={onClick}>
      <div className={`innerContainer ${showAnimation ? 'animate-pulse' : ''}`}>
        <div className="icon">{icon}</div>
        <div className="text">{label}</div>

        {notificationField &&
          isNumber(notificationFieldData) &&
          notificationFieldData > 0 && (
            <div className="notificaitons">{notificationFieldData}</div>
          )}
      </div>
    </Link>
  );
}
