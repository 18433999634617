import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import VendorAPI from "../../../api/VendorAPI";
import { useLocation } from "react-router-dom";
import ItemList from "./itemList";
import Checkout from "./checkout";
import Cart from "./cart";
import Confirm from "./confirm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
const options = {
  mode: "payment",
  amount: 1000,
  currency: "usd",
};
const testItems = [
  {
    id: "1",
    name: "Test Item 1",
    description: "Description for Test Item 1",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "10.00",
    category: "Category 1",
  },
  {
    id: "2",
    name: "Test Item 2",
    description: "Description for Test Item 2",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "15.00",
    category: "Category 1",
  },
  {
    id: "3",
    name: "Test Item 3",
    description: "Description for Test Item 3",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "20.00",
    category: "Category 2",
  },
  {
    id: "4",
    name: "Test Item 4",
    description: "Description for Test Item 4",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "25.00",
    category: "Category 2",
  },
  {
    id: "5",
    name: "Test Item 5",
    description: "Description for Test Item 5",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "30.00",
    category: "Category 3",
  },
  {
    id: "6",
    name: "Test Item 6",
    description: "Description for Test Item 6",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "30.00",
    category: "Category 3",
  },
  {
    id: "7",
    name: "Test Item 7",
    description: "Description for Test Item 7",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "30.00",
    category: "Category 3",
  },
  {
    id: "8",
    name: "Test Item 8",
    description: "Description for Test Item 8",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "30.00",
    category: "Category 3",
  },
  {
    id: "9",
    name: "Test Item 9",
    description: "Description for Test Item 9",
    photos: ["https://via.placeholder.com/150"],
    vendorID: "testVendor",
    price: "30.00",
    category: "Category 3",
  },
];

const Order = ({ setShowSessionSheet }) => {
  const location = useLocation();
  const { event } = location.state || {};
  const [menuItems, setMenuItems] = useState([]);
  const [cart, setCart] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [viewStack, setViewStack] = useState(["order"]); // Stack to manage views

  const { globalState, setGlobalState } = useContext(GlobalContext)

  useEffect(() => {
    const fetchMenuItems = async () => {
      if (event.vendorID) {
        try {
          const items = await VendorAPI.getMenuItemsFromVendor(event.vendorID);
          setMenuItems(testItems || []);
        } catch (error) {
          console.error("Error fetching menu items:", error);
        }
      }
    };

    fetchMenuItems();

  }, [event]);

  useEffect(() => {
    if (globalState.cart && Object.keys(globalState.cart).length) {
      setCart(globalState.cart)
      setTotalItems(globalState.cartTotalItems)
    }
  }, [globalState.cart])

  const handleQuantityChange = (id, delta) => {
    setCart((prev) => {
      const newQuantity = Math.max(0, (prev[id] || 0) + delta);
      const newcart = { ...prev, [id]: newQuantity };

      const newTotalItems = Object.values(newcart).reduce(
        (acc, qty) => acc + qty,
        0
      );
      setTotalItems(newTotalItems);
      setGlobalState(prevState => ({
        ...prevState,
        cart: newcart,
        cartTotalItems: newTotalItems
      }))

      sessionStorage.setItem('cart', JSON.stringify(newcart))
      sessionStorage.setItem('cartTotalItems', newTotalItems)
      return newcart;
    });
  };

  const groupedItems = menuItems.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  const navigateTo = (view) => {
    setViewStack((prevStack) => [...prevStack, view]);
  };

  const goBack = () => {
    setViewStack((prevStack) => prevStack.slice(0, -1));
  };

  const renderOrderMenu = () =>
    ItemList({
      groupedItems,
      cart,
      handleQuantityChange,
      totalItems,
      navigateTo,
    });

  const renderCart = () =>
    Cart({
      groupedItems,
      items: testItems,
      cart,
      handleQuantityChange,
      totalItems,
      navigateTo,
      goBack,
    });

  const renderCheckout = () => (
    <Elements stripe={stripePromise} options={options}>
      <Checkout
        cart={cart}
        handleQuantityChange={handleQuantityChange}
        navigateTo={navigateTo}
        goBack={goBack}
        items={testItems}
      />
    </Elements>
  );

  const renderConfirm = () =>
    Confirm({
      cart,
      totalItems,
      goBack,
      navigateTo,
      setShowSessionSheet,
    });

  const renderCurrentView = () => {
    const currentView = viewStack[viewStack.length - 1];
    switch (currentView) {
      case "cart":
        return renderCart();
      case "checkout":
        return renderCheckout();
      case "confirm":
        return renderConfirm();
      default:
        return renderOrderMenu();
    }
  };

  return <div>{renderCurrentView()}</div>;
};

export default Order;
