import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";

export default function Checkout({
  cart,
  handleQuantityChange,
  navigateTo,
  goBack,
  items,
}) {
  return (
    <div className="pb-20">
      <h1 className="text-2xl font-bold mb-10 text-center">Checkout</h1>
      <ExpressCheckoutElement onConfirm={() => navigateTo("confirm")} />
      <button
        className="w-10 h-10 bg-[#ECF0F4] top-4 left-4 fixed rounded-full font-bold flex items-center justify-center"
        onClick={goBack}
      >
        <ChevronLeftIcon className="h-5 w-5 " />
      </button>
      <div className="fixed bottom-4 left-0 right-0 bg-primary p-4 mx-4 rounded-lg">
        <button
          className="w-full text-white rounded-lg font-bold"
          onClick={() => navigateTo("confirm")}
        >
          Done
        </button>
      </div>
    </div>
  );
}
