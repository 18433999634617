// Candle.js
import React, { useState, useEffect } from "react";
import "./Candle.scss";
const WARN_ANIM_TIME = 30;

const Candle = ({ user, timeLeft, onShowSessionSheet, currentRound }) => {
  const [showIdleState, setShowIdleState] = useState(false);
  const [endWarn, setEndWarn] = useState(false);

  const [dimTimeoutID, setDimTimeoutID] = useState(null);
  const toggleFullscreen = () => {
    if (timeLeft < WARN_ANIM_TIME) {
      setShowIdleState(false);
    } else {
      setShowIdleState(!showIdleState);
      if (showIdleState) {
        dimIn10s();
      }
    }
  };
  useEffect(() => {
    if (timeLeft > WARN_ANIM_TIME) {
      dimIn10s();
    }
  }, []);

  const dimIn10s = () => {
    if (dimTimeoutID) {
      clearTimeout(dimTimeoutID);
    }
    const timeoutID = setTimeout(() => {
      setShowIdleState(true);
    }, 10000);
    setDimTimeoutID(timeoutID);
  };

  useEffect(() => {
    if (timeLeft < WARN_ANIM_TIME) {
      setShowIdleState(false);
      if (timeLeft < 0) {
        setEndWarn(true);
      }
    }
  }, [timeLeft]);

  const breakText = currentRound === 0 ? "Starting in..." : "You're on Break!";
  return (
    <div
      className={`space ${showIdleState ? "fullScreen" : ""}`}
      onClick={toggleFullscreen}
    >
      {user?.isOnBreak || currentRound === 0 ? (
        <BreakAnimation
          showNote={timeLeft >= WARN_ANIM_TIME}
          showIdleState={showIdleState}
          onShowSessionSheet={onShowSessionSheet}
          noteMessage={breakText}
        />
      ) : (
        <StandardView
          timeLeft={timeLeft}
          endWarn={endWarn}
          showIdleState={showIdleState}
        />
      )}
    </div>
  );
};

export default Candle;

const StandardView = ({ timeLeft, endWarn, showIdleState }) => {
  return timeLeft < WARN_ANIM_TIME ? (
    <Warn className={endWarn ? "end" : ""} />
  ) : (
    <CandleLight showIdleState={showIdleState} />
  );
};

function Warn({ className }) {
  return <div className={`warn ${className}`}></div>;
}

function CandleLight({ showIdleState }) {
  return (
    <div className="candle-space">
      <div className="candle">
        <div className={`flame ${!showIdleState ? "animate" : ""}`}>
          <div className="shadows"></div>
          <div className="top"></div>
          <div className="middle"></div>
          <div className="bottom"></div>
        </div>
        <div className="wick"></div>
        <div className="wax"></div>
      </div>
    </div>
  );
}

const BreakAnimation = ({
  showNote,
  showIdleState,
  onShowSessionSheet,
  noteMessage,
}) => {
  const onOrderPressed = (e) => {
    // e.stopPropagation()
    onShowSessionSheet('order');
  };
  return (
    <div className={`break-animation ${showIdleState ? "paused" : ""}`}>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      {showNote && (
        <div className="content">
          <h4 className="on-break-text">{noteMessage}</h4>
          <button className="order" onClick={onOrderPressed}>
            <div className="icon">
              <div className="food">🍔</div>
              <div className="drinks">🍺</div>
            </div>
            <div className="text">
              <div className="pre">...need drinks?</div>
              <div className="large">Order Here</div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};
