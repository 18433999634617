import React, { useState, useEffect, useContext, useRef } from 'react';
import './AbstractChat.scss';
import useHideNav from '../../utils/useHideNav';
import useGoBack from '../../utils/useGoBack';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useLocation, useParams } from 'react-router-dom';
import Utility from '../../utils/utility';
import { GlobalContext } from '../../context/GlobalContext';
import EventsAPI from '../../api/EventsAPI';



const AbstractChat = ({ getChat, addMessageToChat, lastChecked, params }) => {
  const chatNode = 'Chat'
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  const location = useLocation();
  const { match } = location.state || {};
  let { eventID, gender } = useParams();
  if (params?.eventID) {
    eventID = params.eventID;
    gender = params.gender;
  }

  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [title, setTitle] = useState();
  const [chatID, setChatID] = useState();

  const [chatNodeWithPrefix, setChatNodeWithPrefix] = useState();

  useEffect(() => {
    setGlobalState(prevState => ({ ...prevState, showNav: false }));

    return () => {
      if (!params?.eventID) {
        setGlobalState(prevState => ({ ...prevState, showNav: true }));
      }

    };
  }, [setGlobalState]);

  const updateChat = (error, data) => {
    if (error) {
      console.error(error);
      return;
    }
    if (data[chatNodeWithPrefix]) {

      setMessages(data[chatNodeWithPrefix]);
    }
  };

  const goBack = useGoBack();

  useEffect(() => {

    if (chatID && chatNodeWithPrefix) {
      const unsubscribe = getChat(chatID, chatNodeWithPrefix, updateChat, eventID ? EventsAPI.collectionName : undefined);
      if (lastChecked && globalState.user)
        lastChecked(chatID, globalState.user?.userID)
      return () => {
        if (unsubscribe) {
          unsubscribe.unsubDoc();
          unsubscribe.unsubNested();

        }
      };
    }

    // Clean up listeners on unmount

  }, [chatID, eventID, chatNodeWithPrefix, globalState.user]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (match && match.user && match.user.name) {
      setTitle(match.user.name.split(" ")[0])
      setChatID(match.id)
      setChatNodeWithPrefix(chatNode)
    }
    if (eventID && gender) {
      let t = `${gender} Chat`
      setTitle(t)
      setChatID(eventID)
      setChatNodeWithPrefix(gender + chatNode)
    }

  }, [match, eventID, gender])

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim()) {

      const msg = { message: input, userID: globalState.user.userID, name: globalState.user.name };
      await addMessageToChat(chatID, chatNodeWithPrefix, msg, eventID ? EventsAPI.collectionName : undefined);
      setInput('');
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className={`back-button ${params?.eventID ? 'invisible' : ''}`} onClick={goBack}>
          <ChevronLeftIcon />
        </div>
        <h2>{title}</h2>
      </div>
      <div className='chat-space'></div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <ChatMessage key={message.id} index={index} message={message} globalState={globalState} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat-input" onSubmit={sendMessage}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default AbstractChat;

function ChatMessage({ index, message, globalState }) {
  const [showTime, setShowTime] = useState(false);

  const onShowTime = () => {
    setShowTime(!showTime);
  };

  const isYou = () => {
    return message.userID === globalState.user.userID;
  };

  return (
    <div className={`message ${isYou() ? 'you' : 'others'}`}>
      {isYou() ? null : <div className="message-sender">{message.name?.split(" ")[0]}</div>}
      <div className="message-text" onClick={onShowTime}>
        {message.message}
      </div>
      {showTime && <div className="message-timestamp">{Utility.formatDate(message.timestamp)}</div>}
    </div>
  );
}