import { useState, useEffect, useCallback } from 'react';

const useCountdownTimer = (initialMinutes) => {
  const [timeLeft, setTimeLeft] = useState(initialMinutes);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let timerId;
    if (isRunning) {
      timerId = setInterval(() => {
        setTimeLeft(prevTime => {
          return prevTime - 1;
        });
      }, 1000);
    }
    
    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, [isRunning]);

  const resumeTimer = useCallback(() => {
    setIsRunning(true);
  }, []);

  const pauseTimer = useCallback(() => {
    setIsRunning(false);
  }, []);

  const resetTimer = useCallback(() => {
    setIsRunning(true);
    setTimeLeft(initialMinutes);
  }, [initialMinutes]);

  const setCustomTimeLeft = useCallback((customTime) => {
    setTimeLeft(customTime);
  }, []);

  return [timeLeft, resumeTimer, pauseTimer, resetTimer, setCustomTimeLeft];
};

export default useCountdownTimer;