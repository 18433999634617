import React, { useState } from "react";
import "./Details.scss";

import MapComponent from "../MapsComponent";
import Utility from "../../utils/utility";
import {
  ChevronLeftIcon,
  UsersIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import useGoBack from "../../utils/useGoBack";
import Carousel from "../Carousel/Carousel";
import { useNavigate } from "react-router-dom";
import { firebaseConfig } from "../../firebase";
import { BackButton } from "../Buttons/buttons";
const Details = ({ event, backPath, children }) => {
  const [showMap, setShowMap] = useState(false);
  const onAddressTap = () => {
    setShowMap((prevState) => !prevState);
  };
  const { apiKey } = firebaseConfig;
  const navigate = useNavigate();
  const onBack = () => {
    navigate(backPath);
  };
  return (
    <div className="event-details-container">
      <BackButton onClick={onBack} />

      <div className="image-container">
        <Carousel images={event?.photos} />
      </div>
      <div className="content">
        <div>
          <h1 className="text-lg font-bold">{event?.name}</h1>
        </div>
        <div className="mt-4 space-y-2">
          <div className="flex text-primary">
            <CalendarDaysIcon className="w-5 h-5 mr-2" />
            <h1 className="text-sm font-bold">
              {Utility.formatDate(event?.date)}
            </h1>
          </div>
          <div className="flex text-gray-500">
            <UsersIcon className="w-5 h-5 mr-2" />
            <h1 className="text-sm">Age: {event?.ageRange}</h1>
          </div>
          <div className="flex text-gray-500">
            <MapPinIcon className="w-5 h-5 mr-2" />
            <div>
              <h1 className="text-sm font-bold">{event?.hostname}</h1>
              <h1 className="text-sm">{event?.address}</h1>
              <div className="text-sm text-gray-400" onClick={onAddressTap}>
                Show Map
              </div>
            </div>
          </div>
        </div>

        <div className="map-container mt-2">
          {showMap && (
            <MapComponent
              apiKey={apiKey}
              name={event?.hostname}
              address={event?.address}
              coordinates={{
                lat: event?.coordinates?._lat,
                lng: event?.coordinates?._long,
              }}
              zoom={12}
            />
          )}
        </div>
        <p className="description whitespace-pre-line">{event?.description}</p>
        <div className="buttons glass">{children}</div>
      </div>
    </div>
  );
};

export default Details;
