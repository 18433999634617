import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);
  const { isAuthenticated, loading } = globalState;
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    if (loading) return;

    const path = window.location.pathname;
    if (!isAuthenticated) {
      if (path.startsWith("/matches") || path.startsWith("/dates")) {
        localStorage.setItem("returnPath", path); // Save the current path
        navigate("/profile");
      }
    }

    setIsCheckingAuth(false);
  }, [isAuthenticated, loading, navigate]);

  return isCheckingAuth;
};

export default useAuthRedirect;
