import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MatchesList from './MatchesList';
import AbstractChat from '../components/AbstractChat/AbstractChat';
import MatchAPI from '../api/MatchAPI';

function Dates() {

  return (
    <Routes>
      <Route path="/" element={<MatchesList />} />
      <Route
        path="/chat"
        element={
          <AbstractChat 
            getChat={MatchAPI.getMatchChat} 
            addMessageToChat={MatchAPI.addMessageToChat}
            chatNode="Chat" 
            />
        }
      />

    </Routes>
  );
}

export default Dates;