import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DatesList from "./DatesList";
import DatesDetails from "./DatesDetails";
import { DateSession } from "./DateSession";
import DateMatch from "./DateMatch";
import CheckIn from "./CheckIn";
import AbstractChat from "../components/AbstractChat/AbstractChat";
import MatchAPI from "../api/MatchAPI";
import EventsAPI from "../api/EventsAPI";

function Dates() {
  return (
    <Routes>
      <Route path="/" element={<DatesList />} />
      <Route path="/date-details/:eventID" element={<DatesDetails />} />
      <Route path="/date-session/:eventID" element={<DateSession />} />
      <Route path="/date-match" element={<DateMatch />} />
      <Route path="/check-in/:eventID" element={<CheckIn />} />
      <Route
        path="/group-chat/:eventID/:gender"
        element={
          <AbstractChat
            getChat={MatchAPI.getMatchChat}
            addMessageToChat={MatchAPI.addMessageToChat}
            lastChecked={EventsAPI.lastCheckedChat}
          />
        }
      />
    </Routes>
  );
}

export default Dates;
