import React, { useEffect, useState } from "react";

export const ImageWithProgress = ({ src, alt, onLoad, blurCls, onClick }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.alt = alt;

    const handleLoad = () => {
      setIsLoaded(true);
      if (onLoad) {
        onLoad();
      }
    };

    img.addEventListener("load", handleLoad);
    setIsLoaded(false);

    return () => {
      img.removeEventListener("load", handleLoad);
    };
  }, [src, alt]);

  return (
    <div className="image-container" onClick={onClick}>
      <img
        className={`profile-image ${blurCls} ${isLoaded ? "" : "hidden"}`}
        src={src}
        alt={alt}
      />
    </div>
  );
};
