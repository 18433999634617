// src/components/Popup.js

import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline"; // Adjust the path according to your Hero Icons setup
import "./Popup.scss";

const Popup = ({ title, onCancel, children }) => {
  return (
    <div className="pop-up ">
      <div className="cancelArea glass" onClick={onCancel}></div>
      <div className="overlay glass">
        <div className="close-btn" onClick={onCancel}>
          <XCircleIcon />
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
