import React, { useState, useEffect, useRef } from 'react';
import './Carousel.scss';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const intervalRef = useRef(null);
  const autoSlideActive = useRef(true);
  images = images || [];

  useEffect(() => {
    // startAutoSlide();

    return () => clearTimeout(intervalRef.current);
  }, []);

  const startAutoSlide = (idx) => {
    if (autoSlideActive.current) {
      setCurrentIndex(idx);
    }

  };

  const clearAutoSlide = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      autoSlideActive.current = false;
    }
  };

  const goToNext = (e) => {
    if (e) clearAutoSlide();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = (e) => {
    if (e) clearAutoSlide();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    clearAutoSlide();
    setCurrentIndex(index);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50; // You can adjust this value to determine the minimum swipe distance

    if (distance > minSwipeDistance) {
      goToNext(true);
    } else if (distance < -minSwipeDistance) {
      goToPrev(true);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div
      className="carousel"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="carousel-inner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((src, index) => (
          <CarouselItem key={index} src={src} idx={index} onLoad={startAutoSlide} />
        ))}
      </div>
      <button className="carousel-control prev" onClick={goToPrev}>
        &#10094;
      </button>
      <button className="carousel-control next" onClick={goToNext}>
        &#10095;
      </button>
      <CarouselIndicator
        total={images.length}
        currentIndex={currentIndex}
        onClick={goToSlide}
      />
    </div>
  );
};

const CarouselItem = ({ src, idx, onLoad }) => {


  useEffect(() => {
    const img = new Image();
    img.src = src;


    const handleLoad = () => {

      if (onLoad) {
        onLoad(idx);
      }
    };

    img.addEventListener('load', handleLoad);

    return () => {
      img.removeEventListener('load', handleLoad);
    };
  }, [src]);

  return (<div className="carousel-item">
    <img src={src} alt="Carousel" />
  </div>)
}


const CarouselIndicator = ({ total, currentIndex, onClick }) => (
  <div className="carousel-indicators">
    {Array.from({ length: total }).map((_, index) => (
      <span
        key={index}
        className={`indicator ${index === currentIndex ? 'active' : ''}`}
        onClick={() => onClick(index)}
      />
    ))}
  </div>
);

export default Carousel;