const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function ItemList({
  groupedItems,
  cart,
  handleQuantityChange,
  totalItems,
  navigateTo,
}) {
  return (
    <div className="pb-20">
      <h1 className="text-2xl font-bold mb-10 text-center">Vendor name</h1>
      <div className="space-y-8">
        {Object.entries(groupedItems).map(([category, items]) => (
          <div key={category} className="category">
            <h2 className="text-xl font-semibold mb-2">
              {capitalizeFirstLetter(category)}
            </h2>
            <div className="grid grid-cols-2 gap-6">
              {items.map((item) => (
                <div key={item.id} className="menu-item rounded-lg">
                  <div className="relative">
                    <div className="relative h-32 w-full overflow-hidden rounded-lg">
                      <img
                        alt={item.name}
                        src={item.photos[0]}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="absolute inset-x-0 top-0 flex h-32 items-end overflow-hidden rounded-lg">
                      <div
                        aria-hidden="true"
                        className="absolute inset-x-0 bottom-0 h-16 bg-gradient-to-t from-black opacity-50"
                      />
                      <p className="relative text-lg font-semibold text-white p-2 mx-auto">
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-2 w-full py-2">
                    <span className="text-lg font-semibold">${item.price}</span>
                    {cart[item.id] > 0 ? (
                      <div className="flex items-center space-x-2 justify-around bg-primaryDark rounded-full px-1 py-2">
                        <button
                          onClick={() => handleQuantityChange(item.id, -1)}
                          className="px-2 rounded-lg font-bold text-white"
                        >
                          -
                        </button>
                        <span className="font-bold text-white">
                          {cart[item.id]}
                        </span>
                        <button
                          onClick={() => handleQuantityChange(item.id, 1)}
                          className="px-2 rounded-lg font-bold text-white"
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleQuantityChange(item.id, 1)}
                        className="w-10 h-10 bg-primary text-white rounded-full font-bold flex items-center justify-center"
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {totalItems > 0 && (
        <div className="fixed bottom-4 left-0 right-0 bg-primary p-4 mx-4 rounded-lg">
          <button
            className="w-full text-white rounded-lg font-bold"
            onClick={() => navigateTo("cart")}
          >
            View cart ({totalItems})
          </button>
        </div>
      )}
    </div>
  );
}
