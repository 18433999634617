import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./CheckIn.scss";
import {
  ArrowLeftIcon,
  UserIcon,
  ClockIcon,
  BellIcon,
  CameraIcon,
} from "@heroicons/react/24/outline";
import { GlobalContext } from "../context/GlobalContext";
import useGoBack from "../utils/useGoBack";
import Utility from "../utils/utility";
import useCountdownTimer from "../utils/useCountdownTimer";
import Sheets from "../components/Sheets/Sheets";
import ProfileCard from "../profile/ProfileCard";
import useHideNav from "../utils/useHideNav";
import EventsAPI from "../api/EventsAPI";
import { serverTimestamp } from "firebase/firestore";
import useNoSleep from "../utils/useNoSleep";
import useEventStreamingWithGroupChatCount from "./hooks/useEventStreamingWithGroupChatCount";
import SplashScreen from "../components/SplashScreen";
import { PrimaryButton, SecondaryButton } from "../components/Buttons/buttons";

const CheckIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { event } = location.state || {};
  const { globalState } = useContext(GlobalContext);
  const [showSheet, setShowSheet] = useState(false);
  const goBack = useGoBack();
  const { eventID } = useParams();

  const [canStart, setCanStart] = useState(false);
  const [startBtnText, setStartBtnText] = useState("");
  useHideNav();
  const [loading, setLoading] = useState(true);
  const { resetWakeLock } = useNoSleep();
  const { eventStreaming } = useEventStreamingWithGroupChatCount(
    event,
    eventID,
    Utility.getGenderTypeFromGender(globalState.user?.gender)
  );

  const [timeLeft, , pauseTimer, , setCustomTimeLeft] = useCountdownTimer(0);

  useEffect(() => {
    resetWakeLock();
  }, []);

  useEffect(() => {
    if (eventStreaming) {
      setLoading(false);
    }
  }, [globalState.user, eventStreaming]);

  const calcTimeLeftToStart = useCallback((date) => {
    const eventDate = Utility.convertDate(date);
    const currentTime = new Date();
    const timeDiff = eventDate - currentTime;
    return Math.round(timeDiff / 1000);
  }, []);

  const getStartBtnText = useCallback(() => {
    let btnTxt = "Waiting for Start...";
    let eligibleToStart = true;
    const { activeSession, queue } = eventStreaming;

    if (activeSession && activeSession.currentRound) {
      btnTxt = `Joining Round ${activeSession.currentRound}...`;
    }
    if (!queue) {
      btnTxt = "Waiting for Queue...";
      eligibleToStart = false;
    }
    const genderType = Utility.getGenderTypeFromGender(
      globalState.user?.gender
    );
    const genderQueue = queue && queue[genderType];
    if (genderQueue) {
      const idxOfCurrUser = genderQueue.findIndex(
        (u) => u && u.userID == globalState.user?.userID
      );
      if (idxOfCurrUser < 0) {
        btnTxt = "Waiting to be Added...";
        eligibleToStart = false;
      }
    }
    if (activeSession == undefined || activeSession?.currentRound == undefined) {
      eligibleToStart = false;
    }
    setCanStart(eligibleToStart);
    setStartBtnText(btnTxt);
  }, [eventStreaming, globalState.user]);

  useEffect(() => {
    getStartBtnText();
  }, [eventStreaming.queue, eventStreaming.activeSession, getStartBtnText]);

  useEffect(() => {
    if (eventStreaming.date) {
      setCustomTimeLeft(calcTimeLeftToStart(eventStreaming.date));
      if (eventStreaming?.activeSession?.currentRound >= 0) {
        if (eventStreaming?.activeSession?.currentRound === 0) {
          if (startBtnText.length == 0) return;
          setStartBtnText("Starting...");
        }
        const startTimeoutId = setTimeout(() => {
          if (!canStart) return;
          if (!showSheet) {
            goToDateSession();
          } else {
            setStartBtnText(
              `Join Round ${eventStreaming.activeSession.currentRound}`
            );
          }
        }, 10000);

        return () => clearTimeout(startTimeoutId);
      }
    }
  }, [eventStreaming, showSheet, canStart, startBtnText, calcTimeLeftToStart]);

  useEffect(() => {
    if (timeLeft < 0) {
      pauseTimer();
    }
  }, [timeLeft, pauseTimer]);

  const onStartSession = () => {
    if (timeLeft < 0 && canStart) {
      goToDateSession();
    }
  };

  const goToDateSession = () => {
    navigate(`/dates/date-session/${eventID}`);
  };

  const goToDateDetails = () => {
    navigate(`/dates/date-details/${eventStreaming.id}`, {
      state: { event: eventStreaming },
    });
  };

  const onUserUpdate = async (kv) => {
    await EventsAPI.onUpdateUserInQueue(
      kv,
      eventStreaming,
      globalState.user.gender,
      globalState.user.userID
    );
  };

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <div className="check-in" onClick={resetWakeLock}>
      <Toolbar goToDateDetails={goToDateDetails} />
      <CheckInContent
        globalState={globalState}
        eventStreaming={eventStreaming}
        onShowSheet={() => setShowSheet(true)}
        onStartSession={onStartSession}
        timeLeft={timeLeft}
        startBtnText={startBtnText}
        canStart={canStart}
      />
      {showSheet && (
        <Sheets onCancel={() => setShowSheet(false)}>
          <ProfileCard
            showCue={true}
            event={eventStreaming}
            onUserUpdate={onUserUpdate}
          />
        </Sheets>
      )}
    </div>
  );
};

const Toolbar = ({ goToDateDetails }) => (
  <div className="toolbar">
    <button className="back-btn" onClick={goToDateDetails}>
      <ArrowLeftIcon className="icon" />
      Back
    </button>
  </div>
);

const CheckInContent = ({
  globalState,
  eventStreaming,
  onShowSheet,
  onStartSession,
  timeLeft,
  startBtnText,
  canStart,
}) => (
  <>
    <div className="dater-number">
      <CheckInCard globalState={globalState} event={eventStreaming} />
      <div className="logo">
        <img src="/gist_logo.jpg" alt="gist logo" />
      </div>
    </div>

    <SecondaryButton onClick={onShowSheet} className="">
      <div className="flex items-center justify-center">
        <CameraIcon className="w-5 h-5 mr-2" />
        Add Cues & Photo
      </div>
    </SecondaryButton>

    <PrimaryButton
      onClick={onStartSession}
      showAnimation={!!eventStreaming.activeSession?.at && canStart}
    >
      <div className="flex items-center justify-center">
        {timeLeft > 0 ? (
          <>
            <ClockIcon className="w-5 h-5 mr-2" />
            {Utility.formatDateSessionTime(timeLeft, true)}
          </>
        ) : (
          <StartBtn label={startBtnText} />
        )}
      </div>
    </PrimaryButton>
  </>
);

const StartBtn = ({ label }) => (
  <>
    <BellIcon className="w-5 h-5 mr-2" />
    {label}
  </>
);

const CheckInCard = ({ globalState, event }) => {
  const [tableNumber, setTableNumber] = useState(-1);
  const [doesHavePair, setDoesHavePair] = useState(true);

  useEffect(() => {
    const table = getUserTableNumber();
    setDoesHavePair(table !== -1);
    setTableNumber(table);
  }, [globalState.user]);

  const getUserTableNumber = () => {
    if (!globalState.user) return "-";
    const userGenderType = Utility.getGenderTypeFromGender(
      globalState.user?.gender
    );
    if (userGenderType === "women") {
      return event.women.findIndex((u) => u == globalState.user.userID) + 1;
    }
    return -1;
  };

  if (globalState.user == null) return null;

  return (
    <div className="number-display">
      {globalState.user.gender == Utility.gender.male ? (
        <MensCard />
      ) : (
        <WomensCard doesHavePair={doesHavePair} tableNumber={tableNumber} />
      )}
    </div>
  );
};

const WomensCard = ({ doesHavePair, tableNumber }) => (
  <div className="table-card">
    <p>Your table number is</p>
    <h1 className="table-number">{tableNumber}</h1>
    <p className="pair">Wait for your partner at your spot</p>
  </div>
);

const MensCard = () => (
  <div className="px-3">
    <div className="bg-black bg-opacity-15 border-2 rounded-md border-gray-200 w-1/2 h-1/2 flex flex-col justify-center items-center  mx-auto my-5 py-3">
      <UserIcon className="w-24 h-24 text-gray-200 my-2" />
      <p className="text-center text-md font-semibold ">Table Number</p>
      <p className="text-center text-md font-semibold ">Name</p>
      <p className="text-center text-md font-thin ">Cues</p>
    </div>
    <div className="mx-2 m-3">
      <p className="text-md font-thin">
        When the session starts you will see your partner with their table
        number
      </p>
    </div>
  </div>
);

export default CheckIn;
