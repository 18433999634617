// src/hooks/useNoSleep.js

// import NoSleep from 'nosleep.js';

// const useNoSleep = () => {
//   const noSleepRef = useRef(null);

//   useEffect(() => {
//     noSleepRef.current = new NoSleep();

//     const enableNoSleep = () => {
//       noSleepRef.current.enable();
//       console.log('NoSleep enabled');
//     };

//     const disableNoSleep = () => {
//       noSleepRef.current.disable();
//       console.log('NoSleep disabled');
//     };

//     // Enable NoSleep on hook mount
//     enableNoSleep();

//     // Clean up NoSleep on hook unmount
//     return () => {
//       disableNoSleep();
//     };
//   }, []);
// };
import { useEffect, useRef, useCallback } from 'react';

const useNoSleep = () => {
  const wakeLockRef = useRef(null);

  const enableWakeLock = useCallback(async () => {
    try {
      if ('wakeLock' in navigator) {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
        console.log('Wake Lock enabled');
      } else {
        console.log('Wake Lock API is not supported by this browser');
      }
    } catch (err) {
      console.error('Failed to enable Wake Lock:', err);
    }
  }, []);

  const disableWakeLock = useCallback(async () => {
    try {
      if (wakeLockRef.current) {
        await wakeLockRef.current.release();
        wakeLockRef.current = null;
        console.log('Wake Lock disabled');
      }
    } catch (err) {
      console.error('Failed to disable Wake Lock:', err);
    }
  }, []);

  useEffect(() => {
    // Enable Wake Lock on hook mount
    enableWakeLock();

    // Clean up Wake Lock on hook unmount
    return () => {
      disableWakeLock();
    };
  }, [enableWakeLock, disableWakeLock]);

  const resetWakeLock = async () => {
    await disableWakeLock();
    await enableWakeLock();
  };

  return {
    resetWakeLock,
  };
};


export default useNoSleep;