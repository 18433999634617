import React, { useEffect, useState } from "react";

import { FlagIcon } from "@heroicons/react/24/solid";
import { ImageWithProgress } from "./ImageWithProgress";
export const IDCard = ({ user, onReport }) => {
  const [blurCls, setBlurCls] = useState("blur");
  const [message, setMessage] = useState("");
  const onCardTap = () => {
    if (blurCls == "") {
      setBlurCls("blur");
    } else {
      setBlurCls("");
    }
  };

  useEffect(() => {
    setBlurCls("blur");
    setMessage("Loading...");
  }, [user?.photoURL]);

  const onProfileImgLoaded = () => {
    setTimeout(() => {
      setMessage("Tap to See");
    }, 500);
  };
  return (
    <div className={"profile-card"}>
      <div className="profile-content">
        <div className="report-icon" onClick={onReport}>
          <FlagIcon className="icon" /> Report
        </div>

        <ImageWithProgress
          src={user?.photoURL}
          alt={user?.name}
          onLoad={onProfileImgLoaded}
          blurCls={blurCls}
          onClick={onCardTap}
        />
        <div
          className={`text-middle text-2xl animate-pulse ${
            blurCls == "" ? "hide" : ""
          }`}
          onClick={onCardTap}
        >
          {message}
        </div>

        <div
          className={`table-number ${blurCls == "" ? "small" : ""}`}
          onClick={onCardTap}
        >
          {user?.table ? user.table : null}
        </div>

        <h3 className="profile-name" onClick={onCardTap}>
          {user?.name?.split(" ")[0]}
        </h3>

        <div className="profile-description">
          <p>{user?.bio}</p>
        </div>
      </div>
    </div>
  );
};
