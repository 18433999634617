// src/hooks/useEventStreaming.js

import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import Utility from "../../utils/utility";
import EventsAPI from "../../api/EventsAPI";

const useEventStreamingWithGroupChatCount = (event, eventID, genderType) => {
  const { globalState } = useContext(GlobalContext);
  const [eventStreaming, setEventStreaming] = useState(event);
  const [lastCheckedTime, setLastCheckedTime] = useState(new Date(0));

  const isEligibleForUpdate = (event) => {
    if (!globalState.user || !event || !event.lastCheckedChat || !event.lastCheckedChat[globalState.user?.userID]) return;

    let tempTime = lastCheckedTime;
    if (
      lastCheckedTime &&
      !(lastCheckedTime instanceof Date) &&
      lastCheckedTime.seconds
    ) {
      tempTime = Utility.convertDate(lastCheckedTime);
    }




    const updatedEventLastChecked = Utility.convertDate(
      event.lastCheckedChat[globalState.user?.userID]
    );
    if (
      tempTime.getTime() === new Date(0).getTime() ||
      tempTime.getTime() !== updatedEventLastChecked.getTime()
    ) {
      setLastCheckedTime(updatedEventLastChecked);
    }
  };

  useEffect(() => {
    let eventUID = event ? event.id : eventID;
    const stdDate =
      lastCheckedTime instanceof Date ? (d) => d : Utility.convertDate;
    let lastCheckedTs =
      stdDate(lastCheckedTime).getTime() >= new Date(0)
        ? stdDate(lastCheckedTime)
        : new Date(0);
    if (event && event.lastCheckedChat) {
      const currentUserTS = event.lastCheckedChat[globalState.user?.userID];
      if (currentUserTS) lastCheckedTs = currentUserTS;
    }
    setLastCheckedTime(lastCheckedTs);
    if (!genderType || genderType.length == 0) {
      genderType = Utility.getGenderTypeFromGender(globalState.user?.gender);
    }
    if (eventUID && genderType) {
      const unsub = EventsAPI.getEventStreamingWithGroupChatCount(
        eventUID,
        `${genderType}Chat`,
        lastCheckedTs,
        (data) => {
          setEventStreaming(data);
          isEligibleForUpdate(data);
        }
      );
      return () => {
        if (unsub) unsub();
      };
    }
  }, [event, eventID, globalState.user]);

  return { eventStreaming };
};

export default useEventStreamingWithGroupChatCount;
