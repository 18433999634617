import React, { useEffect, useState } from "react";
import "./EventsList.scss";
import Api from "../api/api";
import Utility from "../utils/utility";
import { useNavigate } from "react-router-dom";
import EventsAPI from "../api/EventsAPI";
import {
  UsersIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";

const EventsList = ({ }) => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const handleEventClick = (event) => {
    navigate(`/event-details/${event.id}`, { state: { event } });
  };
  const fetchEvents = async () => {
    try {
      const eventsData = await EventsAPI.getAllEvents();
      setEvents(eventsData);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="events-container hasNav">
      <h2 className="text-lg font-bold">Events</h2>
      <div className="list">
        {events.length ? (
          events.map((item) => {
            return (
              <EventItem
                key={item.id}
                item={item}
                onClick={(_) => handleEventClick(item)}
              />
            );
          })
        ) : (
          <div className="loader"></div>
        )}
      </div>
    </div>
  );
};

export default EventsList;

function EventItem({ item, onClick }) {
  if (item == undefined) return null;

  return (
    <div className="item-container" onClick={onClick}>
      <img src={item.photos[0]} alt={item.name} className="event-image" />
      <div className="info-container">
        <div className="title">{item.name}</div>
        <div className="time-place-age">
          <div className="date space-x-1">
            <CalendarDaysIcon />
            <div>{Utility.formatDate(item.date)}</div>
          </div>
          <div className="hostname space-x-1">
            <MapPinIcon />
            <div>{item.hostname}</div>
          </div>
          <div className="agerange space-x-1">
            <UsersIcon />
            <div>{item.ageRange}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
