import { useState } from 'react';

const useImageResizer = (maxWidth, maxHeight, quality = 0.8) => {
  const setImageForResize = async (file) => {
    if (file) {
      const dataUrl = await readFile(file);
      const img = await loadImage(dataUrl);
      const resizedFile = await resizeImage(img, file.type, quality);
      return resizedFile;
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  const loadImage = (dataUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = dataUrl;
    });
  };

  const resizeImage = (img, mimeType, quality) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], 'resizedImage', { type: mimeType });
        resolve(resizedFile);
      }, mimeType, quality);
    });
  };

  return {
    setImageForResize
  };
};

export default useImageResizer;