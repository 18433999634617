import React from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '230px'
};

const directionBtn = {
  color:"var(--primary-color)"
}
function MapComponent({ apiKey, zoom = 10, name, address, coordinates }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey
  });

  const [map, setMap] = React.useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    // map.fitBounds(bounds);

    setMap(map);
  }, [coordinates]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = () => {
    setInfoWindowOpen(true);
  };

  const handleInfoWindowClose = () => {
    setInfoWindowOpen(false);
  };

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  };

  if (coordinates.lat === undefined || coordinates.lng === undefined) return null;

  const googleMapsUrl = `https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`;

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      <Marker
        position={coordinates}
        onClick={handleMarkerClick}
      >
        {infoWindowOpen && (
          <InfoWindow
            position={coordinates}
            onCloseClick={handleInfoWindowClose}
          >
            <div className='app-info-window'>
              <h3>{name}</h3>
              <p>{address}</p>
              <a href={googleMapsUrl} className='direction-btn' style={directionBtn} target="_blank" rel="noopener noreferrer">
                Get Directions
              </a>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ) : <></>;
}

export default React.memo(MapComponent);