import React, { useEffect, useState, useMemo } from "react";
import Utility from "../../utils/utility";
import { IDCard } from "./IDCard";
import "./MainSection.scss";
import Candle from "./Candle";
const MainSection = ({
  timeLeft,
  user,
  onReport,
  onShowSessionSheet,
  showIDCard,
  setShowIDCard,
  currentRound,
}) => {
  const [animateTime, setAnimateTime] = useState(true);
  const [animateTimeMsg, setAnimateTimeMsg] = useState(true);
  const [showInitialIDCard, setShowInitialIDCard] = useState(true);
  const ONE_MIN = 60 * 1000;
  const HALF_MIN = 30 * 1000;
  const { alertMsg, shouldAnimate } = useMemo(() => {
    let alertMsg = "";
    let shouldAnimate = true;

    if (timeLeft > 15 && timeLeft <= 30) {
      alertMsg = "Get Ready";
    } else if (timeLeft <= 15) {
      alertMsg = "Next Round In";
      shouldAnimate = false;
    }

    return { alertMsg, shouldAnimate };
  }, [timeLeft]);

  useEffect(() => {
    setAnimateTime(shouldAnimate);
    setAnimateTimeMsg(shouldAnimate);
  }, [shouldAnimate]);

  useEffect(() => {
    setShowInitialIDCard(true);
    const timer = setTimeout(() => {
      setShowInitialIDCard(false);
    }, ONE_MIN);

    return () => clearTimeout(timer);
  }, [currentRound, user]);

  useEffect(() => {
    if (showIDCard) {
      const timer = setTimeout(() => {
        setShowIDCard(false);
      }, HALF_MIN);

      return () => clearTimeout(timer);
    }
  }, [showIDCard, setShowIDCard]);

  const isUserActive = !user?.isOnBreak;
  const isValidRound = currentRound !== 0;
  const isEarlyInRound = timeLeft > 30;
  const shouldShowID = showInitialIDCard || showIDCard;

  const isShowingID =
    isUserActive && isValidRound && isEarlyInRound && shouldShowID;

  return (
    <>
      {isShowingID ? (
        <>
          <IDCard user={user} onReport={onReport} />
          <div className={`id-timer`}>
            {Utility.formatDateSessionTime(timeLeft)}
          </div>
        </>
      ) : (
        <>
          <div className={`timer `}>
            <p className={`note ${animateTimeMsg ? "to-flashing" : ""}`}>
              {alertMsg}
            </p>
            <div
              className={`time ${
                animateTime ? "to-large-text" : "to-flashing"
              }`}
            >
              {Utility.formatDateSessionTime(timeLeft)}
            </div>
          </div>
          <Candle
            timeLeft={timeLeft}
            user={user}
            onShowSessionSheet={onShowSessionSheet}
            currentRound={currentRound}
          />
        </>
      )}
    </>
  );
};

export default MainSection;
