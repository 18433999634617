import React, { useEffect, useContext, useState } from "react";
import Details from "../components/Details/Details";
import "./EventDetails.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  LockOpenIcon,
  LockClosedIcon,
  TicketIcon,
  CheckIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import useHideNav from "../utils/useHideNav";
import { GlobalContext } from "../context/GlobalContext";
import EventsAPI from "../api/EventsAPI";
import { AnimatePresence } from "framer-motion";
import Join from "../join/Join";
import Utility from "../utils/utility";
import {
  PrimaryButtonSmall,
  SecondaryButtonSmall,
} from "../components/Buttons/buttons";
import SplashScreen from "../components/SplashScreen";

const EventDetails = ({ children }) => {
  const location = useLocation();
  let { event } = location.state || {};
  const [showJoin, setShowJoin] = useState(false);
  const { globalState } = useContext(GlobalContext);
  const { eventID } = useParams();
  const [eventStreaming, setEventStreaming] = useState(event);

  const navigate = useNavigate();

  useEffect(() => {
    const unsub = EventsAPI.getEventStreaming(event?.id || eventID, (data) => {
      setEventStreaming(data);
    });
    return () => {
      if (unsub) unsub();
    };
  }, [event, eventID]);
  const navigateToAttending = () => {
    navigate(`/dates/date-details/${eventID}`, {
      state: { event: eventStreaming },
    });
  };
  const onJoin = () => {
    if (globalState.user == null) {
      setShowJoin(true);
    } else {
      EventsAPI.addUserToEvent(
        eventStreaming.id,
        globalState.user.userID,
        globalState.user.gender
      );
      navigateToAttending();
    }
  };

  const onLogin = (user) => {
    //window.location.reload()
    EventsAPI.addUserToEvent(eventStreaming.id, user.userID, user.gender);
    setShowJoin(false);
    navigateToAttending();
  };
  const onCancel = () => {
    setShowJoin(false);
  };
  useHideNav();

  if (!eventStreaming || Object.keys(eventStreaming).length == 0) {
    return <SplashScreen />;
  }

  return (
    <>
      <Details event={eventStreaming} backPath="/">
        {/* what if we say "Who's coming" ? */}
        <p className="text-sm text-gray-400 mb-2 font-bold">Attendees</p>
        <AttendeesBar
          event={eventStreaming}
          type={"Women"}
          onJoin={onJoin}
          icon={<TicketIcon />}
          globalState={globalState}
          navigateToAttending={navigateToAttending}
        />
        <AttendeesBar
          event={eventStreaming}
          type={"Men"}
          onJoin={onJoin}
          icon={<TicketIcon />}
          globalState={globalState}
          navigateToAttending={navigateToAttending}
        />
      </Details>
      <AnimatePresence>
        {showJoin && <Join onLogin={onLogin} onCancel={onCancel} />}
      </AnimatePresence>
    </>
  );
};

export default EventDetails;

const AttendeesBar = ({
  event,
  type,
  onJoin,
  icon,
  globalState,
  navigateToAttending,
}) => {
  const MAX_ATTENDEES = 15;
  const navigate = useNavigate();
  const onJoinClick = () => {
    onJoin(type);
  };

  const JoinBtn = (
    // <StandardBtn onJoinClick={onJoinClick} icon={icon} text="Join" />
    <PrimaryButtonSmall
      onClick={onJoinClick}
      text="Join"
      className="flex items-center justify-center w-32"
    >
      <TicketIcon className="w-5 h-5 mr-2" />
      <div className="flex">Join</div>
    </PrimaryButtonSmall>
  );
  const typeAttendees = event[type.toLocaleLowerCase()];
  const isUserAttending = typeAttendees.includes(globalState?.user?.userID);
  const genderToType = Utility.getGenderTypeFromGender(
    globalState.user?.gender,
    false
  );
  let isEligible = type == genderToType;

  const isWomens = type == "Women";
  const isThereMoreWomen = event.women.length >= event.men.length;

  function getStatusText() {
    if (typeAttendees.length >= MAX_ATTENDEES) {
      return "Full";
    }

    return "Open";
    /*
        else if (isThereMoreWomen || isWomens) {
            return "Open";
        } else {
            return "Wait";
        }
        */
  }

  // Usage
  const statusText = getStatusText(event, isWomens);
  const isOpen = statusText == "Open";
  const statusIcon = isOpen ? (
    <LockOpenIcon className="w-5 h-5 mr-2" />
  ) : (
    <LockClosedIcon className="w-5 h-5 mr-2" />
  );

  if (!isEligible && isOpen) {
    isEligible = globalState.user == null ? true : isEligible;
  }

  const Attending = (
    <div className="flex items-center">
      <PrimaryButtonSmall
        onClick={navigateToAttending}
        text="Attending"
        className="flex items-center justify-center w-32"
      >
        <CheckCircleIcon className="w-5 h-5 mr-2" />

        <div className="flex">Attending</div>
      </PrimaryButtonSmall>
    </div>
  );

  const Status = (
    <div className="flex items-center">
      <SecondaryButtonSmall
        onClick={null}
        text={statusText}
        className="flex items-center justify-center w-32"
      >
        {statusIcon}
        <div className="flex">{statusText}</div>
      </SecondaryButtonSmall>
    </div>
  );

  const getAttendeeSection = () => {
    if (isUserAttending) {
      return Attending;
    } else if (isEligible && isOpen) {
      return JoinBtn;
    } else {
      return Status;
    }
  };
  return (
    <div className="attendees bg-white">
      <div className="type">
        {type}: {typeAttendees.length}
      </div>
      {getAttendeeSection()}
    </div>
  );
};

const StandardBtn = ({ onJoinClick, icon, text, cls = "" }) => {
  return (
    <div className={`joinBtn ${cls}`} onClick={onJoinClick}>
      <div className="icon">{icon}</div>

      <div className="joinTxt">{text}</div>
    </div>
  );
};
