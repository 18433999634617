import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export default function Cart({
  cart,
  handleQuantityChange,
  navigateTo,
  goBack,
  items,
}) {
  const cartItems = items.filter((item) => cart[item.id] > 0);

  return (
    <div className="pb-20">
      <h1 className="text-2xl font-bold mb-10 text-center">Cart</h1>
      {cartItems.length > 0 ? (
        cartItems.map((item) => (
          <div
            key={item.id}
            className="menu-item rounded-lg flex items-center mb-4"
          >
            <div className="w-1/4">
              <img
                alt={item.name}
                src={item.photos[0]}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="w-1/2 pl-4">
              <p className="text-lg font-semibold mb-2">{item.name}</p>
              <p className="text-lg font-semibold">${item.price}</p>
            </div>
            <div className="w-1/4 flex justify-end">
              <div className="flex items-center space-x-2 bg-primaryDark rounded-full px-1 py-2">
                <button
                  onClick={() => handleQuantityChange(item.id, -1)}
                  className="px-2 rounded-lg font-bold text-white"
                >
                  -
                </button>
                <span className="font-bold text-white">{cart[item.id]}</span>
                <button
                  onClick={() => handleQuantityChange(item.id, 1)}
                  className="px-2 rounded-lg font-bold text-white"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-lg text-center mt-8">Your cart is empty.</p>
      )}

      <button
        className="w-10 h-10 bg-[#ECF0F4] top-4 left-4 fixed rounded-full font-bold flex items-center justify-center"
        onClick={goBack}
      >
        <ChevronLeftIcon className="h-5 w-5 " />
      </button>
      {cartItems.length > 0 && (
        <div className="fixed bottom-4 left-0 right-0 bg-primary p-4 mx-4 rounded-lg">
          <button
            className="w-full text-white rounded-lg font-bold"
            onClick={() => navigateTo("checkout")}
          >
            Go to Checkout
          </button>
        </div>
      )}
    </div>
  );
}
