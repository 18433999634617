import { useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const useHideNav = () => {
  const { setGlobalState } = useContext(GlobalContext)
  useEffect(() => {
    setGlobalState(prevState => ({ ...prevState, showNav: false }));

    return () => {
      setGlobalState(prevState => ({ ...prevState, showNav: true }));
    };
  }, [setGlobalState]);
};

export default useHideNav;