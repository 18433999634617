import React, { useState, useEffect, useContext } from "react";
import "./MatchesList.scss";
import { Link } from "react-router-dom";
import UserAPI from "../api/UserAPI";
import { GlobalContext } from "../context/GlobalContext";
import MatchAPI from "../api/MatchAPI";
import { PrimaryButtonSmall } from "../components/Buttons/buttons";
import { CogIcon } from "@heroicons/react/24/outline";
import MenuDropdown from "../components/MenuDropdown";
import Notifications from "../components/Popup/Notifications";

const TEXT_NOTIFICATION_FIELD = "allowTextNotifications";

const MatchesList = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [showNotificationsSettingsPopup, setShowNotificationsSettingsPopup] =
    useState(false);
  const [matches, setMatches] = useState([]);
  const { globalState } = useContext(GlobalContext);
  const [showAllowTextNotifications, setShowAllowTextNotifications] =
    useState(false);
  const [signedUpForTexts, setSignedUpForTexts] = useState(false);

  const getStatusOfNotifications = async (user) => {
    const userAccount = await UserAPI.getUserByID(user?.userID);
    if (userAccount && userAccount.length) {
      const isSignedUp = userAccount[0][TEXT_NOTIFICATION_FIELD] === true;
      setSignedUpForTexts(isSignedUp);
      setShowAllowTextNotifications(!isSignedUp);
    }
  };

  useEffect(() => {
    const unsub = MatchAPI.fetchMatches(
      globalState.user,
      (matchesList, hasUser) => {
        setMatches(matchesList);
        hasUser && setShowLoading(false);
      }
    );
    if (globalState.user) {
      getStatusOfNotifications(globalState.user);
    }
    return () => {
      if (unsub) unsub();
    };
  }, [globalState.user]);

  const handleTextNotificationsChange = async (event) => {
    const newValue = event.target.checked;
    await signUpForTextNotifications(newValue);
  };

  const signUpForTextNotifications = async (value) => {
    await UserAPI.updateUser(
      globalState.user.userID,
      TEXT_NOTIFICATION_FIELD,
      value
    );
    setSignedUpForTexts(value);
    setShowAllowTextNotifications(!value);
    let msg = value
      ? "You'll get text notifications"
      : "You have unsubscribed from text notifications";
    alert(msg);
  };

  const showSettings = () => {
    setShowNotificationsSettingsPopup(true);
  };

  const hideSettings = () => {
    setShowNotificationsSettingsPopup(false);
  };

  const menuItems = [
    {
      label: "Notifications",
      icon: CogIcon,
      action: showSettings,
    },
  ];

  return (
    <div className="matches-list">
      <div className="flex justify-between">
        <h2 className="text-lg font-bold">Matches</h2>
        <MenuDropdown
          menuItems={menuItems}
          btnStyle="text-slate-700 hover:text-slate-500"
        />
      </div>

      {matches.length > 0
        ? matches.map((match) => (
          <Link
            to="/matches/chat"
            state={{ match }}
            key={match?.id}
            className="match-item"
          >
            <img
              src={match.user?.photoURL}
              alt={match.user?.name}
              className="profile-pic"
            />
            <div className="match-info">
              <span className="match-name">
                {match.user?.name?.split(" ")[0]}
              </span>
              <span className="match-message">{match?.message}</span>
            </div>
          </Link>
        ))
        : !showLoading && (
          <div className="no-items">
            <p className="text-gray-500 text-lg mb-4">No matches yet.</p>
            {showAllowTextNotifications && (
              <PrimaryButtonSmall>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="textNotificationsHome"
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="textNotificationsHome"
                      checked={signedUpForTexts}
                      onChange={handleTextNotificationsChange}
                      className="mr-2 cursor-pointer"
                    />
                    <span className="text-sm text-white">
                      Allow text notifications
                    </span>
                  </label>
                </div>
              </PrimaryButtonSmall>
            )}
          </div>
        )}
      {showLoading && <div className="loader"></div>}

      {showNotificationsSettingsPopup && (
        <Notifications
          isOpen={true}
          onClose={hideSettings}
          signedUpForTexts={signedUpForTexts}
          onNotificationChange={handleTextNotificationsChange}
        />
      )}
    </div>
  );
};

export default MatchesList;
