// UserAPI.js
import { db, storage } from "../firebase";
import { collection, query, where, orderBy, limit, } from "firebase/firestore";
import { getDocs, updateDoc, arrayUnion, doc, getDoc, setDoc, deleteField } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import MatchAPI from "./MatchAPI";

class UserAPI {
  static collectionName = "users"; // Static property for the collection name

  static async fetchUsersByIds(values) {
    return UserAPI.fetchDocumentsByPropertyValues('userID', values)
  }
  static async fetchDocumentsByPropertyValues(property, values) {
    const MAX_BATCH_SIZE = 10;
    const batches = [];

    for (let i = 0; i < values.length; i += MAX_BATCH_SIZE) {
      const batchValues = values.slice(i, i + MAX_BATCH_SIZE);
      batches.push(UserAPI.fetchBatch(property, batchValues));
    }

    const results = await Promise.all(batches);
    return results.flat();
  }

  static async fetchBatch(property, values) {
    const q = query(
      collection(db, UserAPI.collectionName),
      where(property, "in", values)
    );

    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map(docSnap => ({
      id: docSnap.id,
      ...docSnap.data()
    }));

    return documents;
  }

  static async fetchAllDocuments() {
    const q = query(collection(db, UserAPI.collectionName));
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map(docSnap => ({
      id: docSnap.id,
      ...docSnap.data()
    }));
    return documents;
  }

  static async getUserByID(userID) {
    const usersCollection = collection(db, UserAPI.collectionName);
    const q = query(usersCollection, where("userID", "==", userID));
    const querySnapshot = await getDocs(q);

    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return users;
  }

  //deprecated
  static async fetchMatches(user) {
    if (user) {
      const userID = user.userID;
      const matchesRef = collection(db, "matches");
      const q = query(matchesRef, where("users", "array-contains", userID));

      try {
        const querySnapshot = await getDocs(q);
        const matchesList = [];

        for (const matchDoc of querySnapshot.docs) {
          const matchData = matchDoc.data();
          const matchID = matchDoc.id;

          // Query for the last message in the nested Chat collection
          const chatRef = collection(db, `matches/${matchID}/Chat`);
          const lastMessageQuery = query(chatRef, orderBy("timestamp", "desc"), limit(1));
          const chatSnapshot = await getDocs(lastMessageQuery);

          let lastMessage = null;
          let lastMessageTimestamp = null;
          if (!chatSnapshot.empty) {
            const lastMessageDoc = chatSnapshot.docs[0];
            lastMessage = lastMessageDoc.data().message;
            lastMessageTimestamp = lastMessageDoc.data().timestamp;
          }

          // Determine the other user's ID
          const otherUserID = matchData.users.find(uid => uid !== userID);

          // Fetch the other user's data
          const otherUser = await UserAPI.getUserByID(otherUserID);


          matchesList.push({
            id: matchID,
            user: otherUser[0],
            message: lastMessage,
            at: lastMessageTimestamp
          });
        }

        return matchesList;
      } catch (error) {
        console.error("Error fetching matches: ", error);
        return [];
      }
    }
    return [];
  }

  // Function to add to user likes
  static async addToUserLikesAndMatch(likedUserID, user, eventID) {
    const usersCollection = collection(db, UserAPI.collectionName);
    const likesField = "likes";
    const loggedInUserID = user.userID;

    try {
      // Get the document of the logged-in user
      const q = query(usersCollection, where("userID", "==", loggedInUserID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error("Logged-in user document not found");
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const userDocID = userDoc.id;

      // Add likedUserID to the "likes" array field, ensuring it's not already present
      const userDocRef = doc(db, UserAPI.collectionName, userDocID);
      await updateDoc(userDocRef, {
        [likesField]: arrayUnion(likedUserID)
      });

      // Check if the liked user has already liked the logged-in user
      const likedUserQuery = query(usersCollection, where("userID", "==", likedUserID));
      const likedUserSnapshot = await getDocs(likedUserQuery);

      if (!likedUserSnapshot.empty) {
        const likedUserDoc = likedUserSnapshot.docs[0];
        const likedUserLikes = likedUserDoc.data()[likesField];

        // If the liked user has liked the logged-in user, call MatchAPI.checkAndCreateMatch
        if (likedUserLikes && likedUserLikes.includes(loggedInUserID)) {
          MatchAPI.checkAndCreateMatch(likedUserID, loggedInUserID, eventID);
        }
      }

    } catch (error) {
      console.error("Error updating likes: ", error);
    }
  }


  static async addUser(user) {
    const userDoc = doc(db, UserAPI.collectionName, user.userID);
    const userSnapshot = await getDoc(userDoc);
    if (!userSnapshot.exists()) {
      await setDoc(userDoc, {
        ...user
      });
    }
  }

  static async updateUser(userID, property, value) {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("userID", "==", userID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching user found.");
        return null;
      }

      let updatedUser = null;

      for (const doc of querySnapshot.docs) {
        await updateDoc(doc.ref, {
          [property]: value
        });

        // Fetch the updated document
        const updatedDoc = await getDoc(doc.ref);
        updatedUser = updatedDoc.data();
      }

      console.log("User updated successfully.");
      return updatedUser;

    } catch (error) {
      console.error("Error updating user: ", error);
      throw error;
    }
  }

  static async deleteUserProperty(userID, property) {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("userID", "==", userID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching user found.");
        return null;
      }

      let updatedUser = null;

      for (const doc of querySnapshot.docs) {
        await updateDoc(doc.ref, {
          [property]: deleteField()
        });

        // Fetch the updated document
        const updatedDoc = await getDoc(doc.ref);
        updatedUser = updatedDoc.data();
      }

      console.log("User property deleted successfully.");
      return updatedUser;

    } catch (error) {
      console.error("Error deleting user property: ", error);
      throw error;
    }
  }
  static async uploadUserProfilePhoto(userID, file) {
    const filePath = `profile/${userID}/photo.jpg`;
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }

}

export default UserAPI;