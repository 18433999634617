import React from "react";
import styles from "./QuestionDialog.module.scss";
import { SecondaryButton, PrimaryButton } from "../Buttons/buttons";

const QuestionDialog = ({
  onClosePopup,
  onConfirm,
  question,
  confirmBtnText,
  confirmCls = "",
  swapButtonStyles = false, // New prop to control button style swapping
}) => {
  return (
    <>
      <div className={styles.question}>{question}</div>
      <div className="w-full grid grid-cols-2 gap-4 justify-evenly justify-items-center">
        {swapButtonStyles ? (
          <>
            <PrimaryButton onClick={onClosePopup}>Cancel</PrimaryButton>
            <SecondaryButton onClick={onConfirm}>{confirmBtnText}</SecondaryButton>
          </>
        ) : (
          <>
            <PrimaryButton onClick={onConfirm}>{confirmBtnText}</PrimaryButton>
            <SecondaryButton onClick={onClosePopup}>Cancel</SecondaryButton>
          </>
        )}
      </div>
    </>
  );
};

export default QuestionDialog;
