import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import UserAPI from "../../api/UserAPI";
import { PrimaryButton, SecondaryButton, LinkButton } from "../Buttons/buttons";
import Popup from "./Popup";

export const TEXT_NOTIFICATION_FIELD = "allowTextNotifications";

const Notifications = ({ isOpen, onClose, onChange }) => {
  const [signedUpForTexts, setSignedUpForTexts] = useState(false);
  const { globalState } = useContext(GlobalContext);

  useEffect(() => {
    if (globalState.user) {
      getStatusOfNotifications(globalState.user);
    }
  }, [globalState.user]);

  const getStatusOfNotifications = async (user) => {
    const userAccount = await UserAPI.getUserByID(user?.userID);
    if (userAccount && userAccount.length) {
      setSignedUpForTexts(userAccount[0][TEXT_NOTIFICATION_FIELD] === true);
    }
  };

  const handleTextNotificationsChange = async (event) => {
    const newValue = event.target.checked;
    await signUpForTextNotifications(newValue);
    onChange && onChange(newValue);
  };

  const signUpForTextNotifications = async (value) => {
    UserAPI.updateUser(
      globalState.user.userID,
      TEXT_NOTIFICATION_FIELD,
      value
    );
    setSignedUpForTexts(value);
  };

  const handleSubmit = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Popup onCancel={onClose}>
      <div className="h-full w-full flex flex-col justify-around justify-items-center ">
        <h2 className="text-slate-900 text-lg font-bold text-center">
          Notifications
        </h2>
        <p className="text-slate-500 text-center">
          Get text notifications for important updates during and after the
          event?
        </p>
      </div>
      <div className="w-full grid grid-cols-2 gap-4 justify-evenly justify-items-center">
        <PrimaryButton  >

          <div className="flex items-center justify-center space-x-2">
            <input
              type="checkbox"
              id="textNotifications"
              checked={signedUpForTexts}
              onChange={handleTextNotificationsChange}
              className="form-checkbox h-5 w-5 text-primaryDark"
            />
            <label htmlFor="textNotifications" className="text-white">
              Allow texts
            </label>
          </div>
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>
          Cancel
        </SecondaryButton>
      </div>
    </Popup>
  );
};

export default Notifications;
