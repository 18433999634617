import React, { useContext, useEffect } from "react";
import {
  HomeIcon,
  TicketIcon,
  UserCircleIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { GlobalContext } from "../context/GlobalContext";
import { NavLink, useNavigate } from "react-router-dom";

const ACTIVE_SESSION_FIELD = "activeSession";

function Nav() {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const navigate = useNavigate();
  const isWithinThreshold = (
    dateToCheck,
    beforeThresholdHours,
    afterThresholdHours
  ) => {
    const now = new Date(); // Get the current time
    const timeDifference = dateToCheck - now; // Calculate the time difference in milliseconds
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours

    // Default the after threshold to the before threshold if not specified
    afterThresholdHours =
      typeof afterThresholdHours !== "undefined"
        ? afterThresholdHours
        : beforeThresholdHours;

    return (
      hoursDifference <= afterThresholdHours &&
      hoursDifference >= -beforeThresholdHours
    );
  };

  useEffect(() => {
    let activeSession = localStorage.getItem(ACTIVE_SESSION_FIELD);
    if (activeSession) {
      activeSession = JSON.parse(activeSession);
      activeSession.at = new Date(activeSession.at);
      if (isWithinThreshold(activeSession.at, 1, 3)) {
        navigate(`/dates/date-session/${activeSession.eventID}`);
      } else {
        localStorage.removeItem(ACTIVE_SESSION_FIELD);
      }
    }
  }, []);

  const onLogo = () => {
    navigate("/");
  };

  return (
    globalState.showNav && (
      <nav className="fixed bottom-0 left-0 w-full h-16 flex justify-around items-center bg-white bg-opacity-85 shadow-custom z-10">
        <TabBtn name={"Events"} icon={<HomeIcon />} to="/" />
        {globalState.user != null ? (
          <>
            <TabBtn name={"Attending"} icon={<TicketIcon />} to="dates" />
            <Logo onLogo={onLogo} />
            <TabBtn name={"Matches"} icon={<SparklesIcon />} to="matches" />
          </>
        ) : (
          <Logo onLogo={onLogo} />
        )}
        <TabBtn name={"Profile"} icon={<UserCircleIcon />} to="profile" />
      </nav>
    )
  );
}

const Logo = ({ onLogo }) => {
  return (
    <div className="flex items-center justify-center" onClick={onLogo}>
      <img src="/gist_logo.jpg" className="w-12 h-12 rounded-lg" />
    </div>
  );
};

function TabBtn({ name, icon, to }) {
  return (
    <NavLink
      className={({ isActive }) =>
        `flex flex-col items-center justify-center w-1/6 h-full text-gray-400 ${
          isActive ? "text-primary" : ""
        }`
      }
      to={to}
    >
      {}
      <div className="w-8 h-8">{icon}</div>
      <span className="text-xs">{name}</span>
    </NavLink>
  );
}

export default Nav;
