import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EventsList from './EventsList';
import EventDetails from './EventDetails';
import EventsAPI from '../api/EventsAPI';

function Events() {

  return (
    <Routes>
      <Route path="/" element={<EventsList />} />
      <Route path="event-details" element={<EventDetails />} />
      <Route path="event-details/:eventID" element={<EventDetails />} />
    </Routes>
  );
}

export default Events;