import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Events from "./home/Events";
import Dates from "./dates/Dates";
import Nav from "./nav/Nav";
import { GlobalProvider } from "./context/GlobalContext"; // Import the GlobalProvider
import Matches from "./matches/Matches";
import Profile from "./profile/Profile";
import useAuthRedirect from "./utils/useAuthRedirect";

function App() {
  return (
    <GlobalProvider>
      <Router>
        <div className="App">
          <main>
            <AuthHandler>
              <Routes>
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/matches/*" element={<Matches />} />
                <Route path="/dates/*" element={<Dates />} />
                <Route path="/*" element={<Events />} />
              </Routes>
            </AuthHandler>
          </main>
          <Nav />

          {/* <PWAPrompt promptOnVisit={1} timesToShow={5} copyClosePrompt="Close" permanentlyHideOnDismiss={false} /> */}
        </div>
      </Router>
    </GlobalProvider>
  );
}

export default App;

const AuthHandler = ({ children }) => {
  const isCheckingAuth = useAuthRedirect();

  if (isCheckingAuth) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="loader m-auto">
          <p>{"\u200B"}</p>
        </div>
      </div>
    );
  }

  return children;
};
