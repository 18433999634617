import React from "react";
// import "tailwindcss/tailwind.css";

export const Input = ({ label, type, value, onChange, placeholder, klass }) => {
  return (
    <input
      onChange={onChange}
      id="phone"
      value={value}
      name="phone"
      type={type}
      autoComplete="phone"
      required
      placeholder={placeholder}
      className={`px-4 block w-full rounded-md border-x border-y border-slate-300
      text-center border-0 py-2
      text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200
      placeholder:text-gray-400 focus:ring-2 
      text-xl focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${klass}`}
    />
  );
};

export const Select = ({ label, type, value, onChange, options }) => {
  return (
    <select
      onChange={onChange}
      value={value}
      className="px-4 block w-full rounded-md 
      text-center
      border-0 py-2
      text-gray-900 shadow-sm 
      ring-1 ring-inset ring-gray-200
      placeholder:text-gray-400 focus:ring-2 
      text-xl
      focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
    >
      {options.map((option, i) => (
        <option value={option} key={i}>{option}</option>
      ))}
    </select>
  );
};
