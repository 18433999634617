// src/components/SplashScreen.js
import React from 'react';
import './index.scss';

const SplashScreen = () => {
  return (
    <div className='splash'>
      <div className="logo">
        <img src="/gist_logo.jpg" alt="gist logo" />
      </div>
    </div>
  );
};

export default SplashScreen;