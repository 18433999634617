// src/context/GlobalContext.js
import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Api from "../api/api";
import UserAPI from "../api/UserAPI";
import { json } from "react-router-dom";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    user: null,
    isAuthenticated: false,
    showNav: true,
    cart: [],
    cartTotalItems: 0,
    loading: true,

    // Add more global state variables as needed
  });

  useEffect(() => {
    const unsubscribe = Api.onAuthStateChanged(async (fbuser) => {
      if (fbuser) {
        const ls_user = localStorage.getItem("user");
        const cachedUser =
          ls_user && ls_user !== "undefined" ? JSON.parse(ls_user) : {};

        let user;
        if (ls_user && cachedUser?.userID == fbuser.uid) {
          user = cachedUser;
          UserAPI.getUserByID(fbuser.uid).then(gistUser => {
            localStorage.setItem("user", JSON.stringify(gistUser[0]));

          })

        } else {
          const gistUser = await UserAPI.getUserByID(fbuser.uid);
          localStorage.setItem("user", JSON.stringify(gistUser[0]));
          user = gistUser[0];
        }
        setGlobalState((prevState) => ({
          ...prevState,
          user,
          isAuthenticated: true,
          loading: false,
        }));
      } else {
        localStorage.removeItem("user");
        setGlobalState((prevState) => ({
          ...prevState,
          user: null,
          isAuthenticated: false,
          loading: false,
        }));
      }
    });

    const cart = sessionStorage.getItem("cart");
    const cartTotalItems = sessionStorage.getItem("cartTotalItems");
    if (cart || cartTotalItems) {
      setGlobalState((prevState) => ({
        ...prevState,
        cart: JSON.parse(cart),
        cartTotalItems: JSON.parse(cartTotalItems),
      }));
    }

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
