import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

export default class VendorAPI {
  static async getMenuItemsFromVendor(vendorID) {
    try {
      const menuRef = collection(db, "menu");
      const q = query(menuRef, where("vendorID", "==", vendorID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching menu found.");
        return null;
      }

      const menuItems = [];
      querySnapshot.forEach((doc) =>
        menuItems.push({ id: doc.id, ...doc.data() })
      );
      return menuItems;
    } catch (error) {
      console.error("Error fetching menu items: ", error);
      throw error;
    }
  }
}
