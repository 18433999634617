import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export default function Confirm({ cart, setShowSessionSheet }) {
  return (
    <div className="pb-20">
      <h1 className="text-2xl font-bold mb-10 text-center">Order #123</h1>
      Order detail goes here
      <div className="fixed bottom-4 left-0 right-0 bg-primary p-4 mx-4 rounded-lg">
        <button
          className="w-full text-white rounded-lg font-bold"
          onClick={() => setShowSessionSheet(false)}
        >
          Done
        </button>
      </div>
    </div>
  );
}
