import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
// import "tailwindcss/tailwind.css";

export const PrimaryButton = ({ onClick, showAnimation, children, className = '' }) => {
  return (
    <button
      className={`${className} w-full truncate text-white bg-primary py-2 px-4 rounded hover:bg-primary-dark text-xl border-none ${showAnimation ? "animate-pulse" : ""
        }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const PrimaryButtonSmall = ({ onClick, children, className = '' }) => {
  return (
    <button
      className={`truncate text-white bg-primary py-2 px-4 rounded hover:from-primary-dark hover:to-primary transition duration-200 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const SecondaryButtonSmall = ({ onClick, children, className = '' }) => {
  return (
    <button
      className={`truncate bg-white text-primary border border-primary py-2 px-4 rounded hover:bg-gray-100 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({ onClick, children }) => {
  return (
    <button
      className="truncate text-primary ring-primary ring-1 bg-white py-2 px-4 rounded hover:bg-primary-dark w-full text-lg"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const LinkButton = ({ onClick, children }) => {
  return (
    <button
      className="truncate text-primary hover:underline text-xl"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <div
      className="truncate  z-10 top-6 left-4 p-2 fixed bg-gray-100 rounded-full shadow-lg opacity-90"
      onClick={onClick}
    >
      <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
    </div>
  );
};

export const CircleButton = ({ onClick, children }) => {
  return (
    <button
      className=" z-10 top-6 right-4 p-2 fixed bg-gray-100 rounded-full shadow-sm opacity-90"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
